import { OnboardingTooltip, UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import { usePromoSearchTypesOnboarding } from './hooks';
import { NBSP } from '../../../../../../../../shared/constants/symbols';

// TODO: В CD-218665 удалить
export const PromoSearchTypesOnboardingContainer: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { open, closeHandler } = usePromoSearchTypesOnboarding();

  const eventPreventionHandler = React.useCallback((event: React.MouseEvent) => event.preventDefault(), []);

  return (
    <OnboardingTooltip
      theme="black"
      content={
        <UIText2 color="white_100" whiteSpace="pre-line">
          Добавили новые фильтры{NBSP}— для{NBSP}поиска выгодной ипотеки и{NBSP}скидок от
          {NBSP}застройщиков
        </UIText2>
      }
      placement="top-start"
      portal={false}
      open={open}
      onClose={closeHandler}
    >
      <span onClick={eventPreventionHandler}>{children}</span>
    </OnboardingTooltip>
  );
};
