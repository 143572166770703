import { FavoriteButtonWrapperContainer } from '@cian/favorites-assign-widget';
import { IconButton } from '@cian/ui-kit';
import { IconToggleHeartOn16, IconToggleHeartOff16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dispatch } from '../../common/state';
import { changeLayoutFavorite } from '../../filters/state/favoriteLayout';
import { isAuthenticatedUserSelector } from '../../selectors/isAuthenticatedUserSelector';
import { residentialComplexLayoutByOfferIdSelector } from '../../selectors/newbuildingLayouts';
import { INewbuildingLayoutOffer } from '../../types/residentialComplexLayouts/layoutWithOffers/offer';

export interface IFavoritesControlContainerProps {
  offerId: number;
}
export function FavoritesControlContainer({ offerId }: IFavoritesControlContainerProps) {
  const dispatch: Dispatch = useDispatch();
  const offer = useSelector(residentialComplexLayoutByOfferIdSelector)(offerId) as INewbuildingLayoutOffer;
  const isAuthenticated = useSelector(isAuthenticatedUserSelector);

  const [isFavorite, setFavorite] = React.useState(Boolean(offer.isFavorite));

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(changeLayoutFavorite(offerId, !isFavorite));
    setFavorite(!isFavorite);
  };

  const handleLogin = React.useCallback(() => {
    if (window.waitForLoginPopup) {
      window.waitForLoginPopup('favorites');
    }
  }, []);

  const button = (
    <IconButton
      data-mark="FavoritesControl"
      onClick={handleClick}
      color={isFavorite ? 'error_100' : 'gray_icons_100'}
      icon={
        isFavorite ? (
          <div>
            <IconToggleHeartOn16 color="error_100" />
          </div>
        ) : (
          <div>
            <IconToggleHeartOff16 />
          </div>
        )
      }
    />
  );

  if (!offer) {
    return null;
  }

  return (
    <FavoriteButtonWrapperContainer
      offerId={offer.offerId}
      placement="bottom-end"
      isAuthenticated={isAuthenticated}
      onWaitForLogin={handleLogin}
    >
      {button}
    </FavoriteButtonWrapperContainer>
  );
}
