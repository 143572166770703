import * as React from 'react';
import { useSelector } from 'react-redux';

import {
  trackNewbuildingLayoutDetailsOpen,
  trackNewbuildingLayoutOfferClick,
  trackNewbuildingLayoutOffersListingClick,
} from './tracking';
import { ModalContent } from '../../components/NewbuildingLayoutOffersModal/components/ModalContent';
import { ModalHeader } from '../../components/NewbuildingLayoutOffersModal/components/ModalHeader';
import { OffersTable } from '../../components/NewbuildingLayoutOffersModal/components/OffersTable';
import { residentialComplexLayoutOffersSelector } from '../../selectors/newbuildingLayouts';
import { NewbuildingHeaderCallContainer } from '../NewbuildingHeaderCall';

export const NewbuildingLayoutDetails = () => {
  const layout = useSelector(residentialComplexLayoutOffersSelector);

  React.useEffect(() => {
    trackNewbuildingLayoutDetailsOpen();
  }, []);

  const onOfferLinkClick = React.useCallback(() => {
    trackNewbuildingLayoutOfferClick();
  }, []);

  const onOffersListingLinkClick = React.useCallback(() => {
    trackNewbuildingLayoutOffersListingClick();
  }, []);

  if (!layout) {
    return null;
  }

  return (
    <ModalContent>
      <ModalHeader
        contactsButton={<NewbuildingHeaderCallContainer action="Open_layout_modal_block" />}
        layout={layout}
      />
      <OffersTable
        onOfferLinkClick={onOfferLinkClick}
        onOffersListingLinkClick={onOffersListingLinkClick}
        layout={layout}
      />
    </ModalContent>
  );
};
