import { useCallback, useState } from 'react';

import { useContext } from '../../../../../../utils/useContext';
import { getPromoSearchTypesOnboardingCookie, setPromoSearchTypesOnboardingCookie } from '../../../utils';

interface IOnboardingTooltipResult {
  open: boolean;
  closeHandler: () => void;
}

export const usePromoSearchTypesOnboarding = (): IOnboardingTooltipResult => {
  const {
    appContext: { config },
  } = useContext();

  const isOnboardingEnabled = config.get<boolean>('newbuildingPromoSearchTypesOnboarding.Enabled') ?? false;
  const isOnboardingShown = getPromoSearchTypesOnboardingCookie();

  const [open, setOpen] = useState(isOnboardingEnabled && !isOnboardingShown);

  const closeHandler = useCallback(() => {
    setOpen(false);
    setPromoSearchTypesOnboardingCookie();
  }, []);

  return {
    open,
    closeHandler,
  };
};
