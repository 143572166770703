import * as React from 'react';
import { useSelector } from 'react-redux';

import { getNewbuildingId } from '../../selectors/newbuildingHeader';
import { trackPhoneShow } from '../../tracking/newbuilding';
import { ENewbuildingCallPlaceType } from '../../types/newbuildingHeader';
import { NewbuildingCallButtonContainer } from '../NewbuildingCallButton';

interface INewbuildingHeaderCallContainerProps {
  action?: string;
}

export const NewbuildingHeaderCallContainer = ({
  action = 'Open_jk_head_block',
}: INewbuildingHeaderCallContainerProps) => {
  const newbuildingId = useSelector(getNewbuildingId);

  const onShowPhoneClick = React.useCallback(() => {
    if (newbuildingId) {
      trackPhoneShow({ newbuildingId, action });
    }
  }, [newbuildingId, action]);

  return (
    <NewbuildingCallButtonContainer
      placeType={ENewbuildingCallPlaceType.NewbuildingHeaderInSerp}
      onShowPhoneClick={onShowPhoneClick}
      openContactsStyles={{ size: 'M' }}
      showPhoneStyles={{ size: 'M' }}
    />
  );
};
