import { ca } from '@cian/analytics';

export const trackNewbuildingLayoutOffersListingClick = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Layout',
    action: 'show_sopr',
    label: 'modal_open_listing_jk',
  });
};
