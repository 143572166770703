import { ModalWindow } from '@cian/ui-kit';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackNewbuildingLayoutOfferClick } from './tracking';
import { getNewbuildingLayoutOffers } from '../../actions/newbuildingLayoutOffers';
import { Card } from '../../components/NewbuildingLayoutsCard';
import { Cards } from '../../components/NewbuildingLayoutsCards';
import { residentialComplexLayoutsSelector } from '../../selectors/newbuildingLayouts';
import { TThunkDispatch } from '../../types/redux';
import { INewbuildingLayout } from '../../types/residentialComplexLayouts/layouts/newbuildingLayout';
import { NewbuildingLayoutDetails } from '../NewbuildingLayoutDetails';

export const NewbuildingLayoutsContainer = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const [open, setOpen] = React.useState<boolean>(false);
  const layouts = useSelector(residentialComplexLayoutsSelector);

  const onClick = React.useCallback((key: string) => {
    dispatch(getNewbuildingLayoutOffers({ layoutKey: key }));
    setOpen(true);
  }, []);

  const onClose = React.useCallback(() => setOpen(false), []);

  if (!layouts) {
    return null;
  }

  return (
    <div>
      <Cards>
        {layouts.map((layout: INewbuildingLayout) => {
          return (
            <Card
              key={layout.layoutKey}
              layout={layout}
              onClick={onClick}
              onLinkClick={trackNewbuildingLayoutOfferClick}
            />
          );
        })}
      </Cards>
      <ModalWindow open={open} onClose={onClose} size="M" fixed={false}>
        <NewbuildingLayoutDetails />
      </ModalWindow>
    </div>
  );
};
