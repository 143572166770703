import { Label, UIText1 } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './style.css';
import { INewbuildingLayoutOffer } from '../../../../types/residentialComplexLayouts/layoutWithOffers/offer';

interface IOffersRowProps {
  offer: INewbuildingLayoutOffer;
  onOfferLinkClick(): void;
}

export const OffersRow = ({ offer, children, onOfferLinkClick }: React.PropsWithChildren<IOffersRowProps>) => {
  return (
    <div className={style['row']}>
      <div className={style['offer-title']}>
        <UIText1>
          <a
            className={style['offer-link']}
            href={offer.singleOfferUrl}
            onClick={onOfferLinkClick}
            target="_blank"
            rel="noreferrer"
          >
            {offer.title}
          </a>
        </UIText1>
        <div>
          {offer.hasDecoration && (
            <Label background="rgba(0, 0, 0, 0.06)" color="--text-primary">
              С отделкой
            </Label>
          )}
        </div>
      </div>
      <div className={style['description-cell']}>
        <UIText1 color="current_color">{offer.buildingDeadlineShorten}</UIText1>
      </div>
      <div className={style['description-cell']}>
        <UIText1 color="current_color">{offer.floor}</UIText1>
      </div>
      <div className={style['description-cell']}>
        <UIText1 color="black_100" textAlign="right" as="span">
          {offer.priceText}
        </UIText1>
      </div>
      <div className={style['controls-cell']}>{children}</div>
    </div>
  );
};
